import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { imageResizerService } from '../../../domain/services/imageResizerService';
import { CardStackItem } from './CardStackItem';
import { CoverFlowWrapper } from '../CoverFlowWrapper';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { getColors } from '../../../domain/utils/colorUtils';

const CardStackInner = ({ data, isAboveTheFold }) => {
  const {
    carouselCard,
    brandedLogo,
    brandedClickThrough,
    carouselTitle,
    carouselSubtitle
  } = data;

  const colors = getColors(data.backgroundColor);
  const backgroundImageEntity = imageResizerService.overrideImageFocalPoint(
    data.backgroundImage?.[0]
  );
  const backgroundImage = backgroundImageEntity?.mediaImage?.url;
  const backgroundImageUrls = backgroundImage
    ? imageResizerService.getStyleUrlsByBreakpoint(backgroundImage, {
        position: backgroundImageEntity?.focalPoint
      })
    : null;
  const backgroundColor = backgroundImage ? '#000' : colors.background;

  const fontColor = backgroundImage ? 'white' : colors.color;

  const backgroundImageStyles = backgroundImageUrls?.mobile
    ? css`
        background: url(${backgroundImageUrls.mobile}) no-repeat
          ${imageResizerService.getBackgroundFocalPoint(
            backgroundImageEntity?.focalPoint
          )};
        ${theme.mq.tablet} {
          background-image: url(${backgroundImageUrls.tablet});
        }
        ${theme.mq.small_desktop} {
          background-image: url(${backgroundImageUrls.small_desktop});
        }
        ${theme.mq.large_desktop} {
          background-image: url(${backgroundImageUrls.large_desktop});
        }
      `
    : null;

  const styles = {
    mainContainer: css`
      background-color: ${backgroundColor};
      width: 100%;
    `,
    carouselWrapper: css`
      display: block;
      color: ${fontColor};
      position: relative;
      text-align: center;
      padding-bottom: ${theme.spacing('xs')};
      ${theme.mq.small_desktop} {
        padding-bottom: ${theme.spacing('xl2')};
      }
      &:before {
        background-color: ${backgroundColor};
        ${backgroundImageStyles}
        background-size: cover;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: ${data.mediaTint ? (100 - data.mediaTint) / 100 : 1};
      }
    `
  };

  const carouselItems = carouselCard.map(card => {
    return {
      key: card.entity.key || card.entity.id,
      orientation: 'portrait',
      element: (
        <CardStackItem data={card.entity} isAboveTheFold={isAboveTheFold} />
      )
    };
  });

  const branded = { logo: brandedLogo, link: brandedClickThrough?.url?.path };
  return (
    <section css={styles.mainContainer}>
      <div css={styles.carouselWrapper}>
        <CoverFlowWrapper
          items={carouselItems}
          numberOfVisibleItems={5}
          widthSize="medium"
          backgroundColor={backgroundColor}
          branded={branded.logo ? branded : null}
          heading={carouselTitle}
          description={carouselSubtitle}
          isAboveTheFold={isAboveTheFold}
        />
      </div>
    </section>
  );
};

CardStackInner.defaultProps = {
  isAboveTheFold: false
};

CardStackInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};

export const CardStack = ParagraphWrapper(CardStackInner);
