import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { SmartLink } from '../../SmartLink';
import { Image } from '../../Image';
import { cardTagSponsored } from '../../Styles/card';
import { Button } from '../../responsive/atoms/Button';
import { getMainImages } from '../../../domain/utils/contentUtils';

export const CardStackItem = ({ data, isAboveTheFold }) => {
  const imageWidth = 390;
  const styles = {
    carouselCard: css`
      position: relative;
      width: 100%;
      aspect-ratio: 3 / 4;
      color: #fff;
      a {
        color: #fff;
      }
    `,
    imageContainer: css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      aspect-ratio: 3 / 4;
      &:after {
        box-shadow: inset 0 90px 90px -30px #000;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
      }
    `,
    contentContainer: css`
      position: relative;
      overflow: hidden;
      z-index: 1;
      text-align: center;
      aspect-ratio: 3 / 4;
    `,
    sponsoredContent: css`
      margin-top: ${theme.spacing('base')};
      text-transform: uppercase;
    `,
    ptt: css`
      position: relative;
      ${theme.font.family('boldWeb')};
      font-size: ${theme.font.size('xs')};
      letter-spacing: ${theme.letterSpacing('base')};
      text-transform: uppercase;
      text-shadow: 0 0 4px #000;
      margin-top: ${theme.spacing('base')};
      &:before {
        content: '';
        position: absolute;
        width: 30px;
        height: 1px;
        top: 27px;
        bottom: 0;
        left: 50%;
        margin-left: -15px;
        border-top: 4px solid #fc0;
      }
    `,
    cardTitle: css`
      ${theme.font.family('regularWeb')};
      font-size: ${theme.font.size('l')};
      font-weight: ${theme.font.weight('l')};
      letter-spacing: ${theme.letterSpacing('s')};
      margin: ${theme.spacing.parse('$base 0 auto')};
      padding: ${theme.spacing.parse('0 $base')};
      text-shadow: 0 0 4px #000;
    `,
    cardSubtitle: css`
      ${theme.font.family('regularWeb')};
      font-size: ${theme.font.size('s')};
      font-weight: ${theme.font.weight('base')};
      letter-spacing: ${theme.letterSpacing('s')};
      margin-bottom: ${theme.spacing('xs2')};
      padding: ${theme.spacing.parse('0 $base')};
      text-shadow: 0 0 4px #000;
    `,
    ctaButton: css`
      position: absolute;
      bottom: 40px;
      width: 100%;
      text-align: center;
    `
  };

  const title =
    data.title ||
    data.carouselRefCard?.entity?.promoTitle ||
    data.carouselRefCard?.entity?.title;
  // Subtitle is optional, and it's not filled in by default.
  const { subtitle } = data;
  const image =
    data.cardThumbnail?.entity ||
    (data.carouselRefCard?.entity
      ? getMainImages(data.carouselRefCard.entity)[0]?.entity
      : null);
  image.focalPoint = data.focalPoint || image.focalPoint;
  const link =
    data.carouselRefCard?.entity?.link?.url?.path ||
    data.carouselRefCard?.entity?.url?.path ||
    data.link?.url?.path;
  const cta = data.link?.title;
  const isSponsoredContent =
    data.carouselRefCard?.entity?.sponsoredTextCard &&
    data.carouselRefCard?.entity?.branded &&
    data.showExternalSponsorLabel;
  const pttTitle =
    data.carouselRefCard?.entity?.primaryTaxonomy?.entity?.hubPage?.title;

  const card = (
    <>
      <div css={styles.imageContainer}>
        {image ? (
          <Image
            entity={image}
            maxWidths={{
              mobile: imageWidth,
              tablet: imageWidth,
              small_desktop: imageWidth,
              large_desktop: imageWidth
            }}
            aspect="3x4"
            loading={isAboveTheFold ? 'eager' : 'lazy'}
          />
        ) : null}
      </div>
      <div css={styles.contentContainer}>
        {isSponsoredContent && (
          <div
            className="card__tag--sponsored"
            css={[styles.sponsoredContent, cardTagSponsored]}
          >
            {data.carouselRefCard.entity.sponsoredTextCard}
          </div>
        )}
        {pttTitle ? <div css={styles.ptt}>{pttTitle}</div> : null}
        {title && <div css={styles.cardTitle}>{title}</div>}
        {subtitle && <div css={styles.cardSubtitle}>{subtitle}</div>}
        {cta && (
          <div css={styles.ctaButton}>
            <Button color="primary" padding="xs">
              {cta.toUpperCase()}
            </Button>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div css={styles.carouselCard}>
      {link ? (
        <SmartLink
          to={link}
          target={data.openLinkInNewTab ? '_blank' : null}
          title={data.carouselRefCard?.entity?.promoTitle || data.link?.title}
        >
          {card}
        </SmartLink>
      ) : (
        card
      )}
    </div>
  );
};

CardStackItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool.isRequired
};
